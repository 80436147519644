<template>
     <div class="resultcontainer">
          <div style="text-align: center;width:100%;padding:0 30px;" class="container ">
               <h2>
                    Your presentation has been updated!
                    <img style="width:35px;position:relative;top:6px;left:10px" src="@/assets/images/celebration.png" alt="" />
               </h2>

               <a target="_blank" :href="pptURL"> <b-button class="downloadbtn custombtn1" type="is-coral">Download</b-button></a>
               <button class="button tryanother" @click="$router.push({ name: 'HOME' })">
                    <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                         <path
                              d="M14.2794 5.30604L13.5418 4.448C12.9164 3.79117 12.164 3.26845 11.3302 2.91165C10.4964 2.55485 9.59872 2.37143 8.69179 2.37256C4.99002 2.37256 1.98669 5.37589 1.98669 9.07766C1.98669 12.7794 4.99002 15.7828 8.69179 15.7828C10.0786 15.7827 11.4312 15.3528 12.5636 14.5523C13.696 13.7518 14.5525 12.62 15.0152 11.3127"
                              stroke="#F2836B"
                              stroke-width="1.11752"
                              stroke-miterlimit="10"
                              stroke-linecap="round"
                         />
                         <path
                              d="M16.5144 3.53965V7.40137C16.5144 7.54956 16.4555 7.69168 16.3507 7.79647C16.2459 7.90126 16.1038 7.96013 15.9556 7.96013H12.0939C11.5959 7.96013 11.3466 7.35842 11.6986 7.0064L15.5607 3.14433C15.9127 2.79161 16.5144 3.04166 16.5144 3.53965Z"
                              fill="#F2836B"
                         />
                    </svg>
                    Try another
               </button>
          </div>

          <div style="width:100%;" v-if="dataReady">
               <div class="horizontalCarouselContainer hidden-desktop ">
                    <hooper
                         group="group1"
                         :settings="hooperSettingsHorizontal"
                         id="carouselControlHorizontal"
                         class=""
                         style="width:100%;height:100%"
                    >
                         <slide class="slide ">
                              <div class="slidecontainer" @click="changeSlide(0)">
                                   <div class="imagestack">
                                        <img class="slideimage" v-if="json.slides[2]" :src="json.slides[2].image" alt="" />
                                        <img class="slideimage" v-if="json.slides[1]" :src="json.slides[1].image" alt="" />
                                        <img class="slideimage" :src="json.slides[0].image" alt="" />
                                   </div>
                              </div>
                         </slide>

                         <slide class="slide " v-for="(item, index) in json.slides" :key="index">
                              <div class="slidecontainer" @click="changeSlide(index + 1)"><img :src="item.image" alt="" /></div>
                         </slide>

                         <hooper-pagination slot="hooper-addons" mode="fraction"></hooper-pagination>

                         <hooper-navigation slot="hooper-addons"></hooper-navigation>
                    </hooper>
               </div>

               <div class="columns  is-desktop carouselContainer ">
                    <div class="column  is-3   hidden-mobile">
                         <hooper
                              group="group1"
                              :settings="hooperSettingsVertical"
                              id="carouselControlVertical"
                              ref="carouselMain"
                              class=""
                              style="width:100%;height:100%"
                         >
                              <slide class="slide ">
                                   <div class="slidecontainer" @click="changeSlide(0)">
                                        <div class="imagestack">
                                             <img class="slideimage" v-if="json.slides[2]" :src="json.slides[2].image" alt="" />
                                             <img class="slideimage" v-if="json.slides[1]" :src="json.slides[1].image" alt="" />
                                             <img class="slideimage" :src="json.slides[0].image" alt="" />
                                        </div>
                                   </div>
                              </slide>

                              <slide class="slide" v-for="(item, index) in json.slides" :key="index">
                                   <div class="slidecontainer" @click="changeSlide(index + 1)"><img :src="item.image" alt="" /></div>
                              </slide>

                              <hooper-pagination slot="hooper-addons" mode="fraction"></hooper-pagination>

                              <hooper-navigation slot="hooper-addons"></hooper-navigation>
                         </hooper>
                    </div>
                    <div class="column  is-9-desktop   ">
                         <hooper
                              group="group1"
                              :itemsToSlide="1"
                              :itemsToShow="1"
                              :mouseDrag="true"
                              :touchDrag="true"
                              :wheelControl="false"
                              id="carouselMain"
                              :centerMode="true"
                              class=""
                              style="width:100%;height:auto"
                         >
                              <slide class="slide ">
                                   <div class="slidecontainer  ">
                                        <div class="imagestack">
                                             <img class="slideimage" v-if="json.slides[2]" :src="json.slides[2].image" alt="" />
                                             <img class="slideimage" v-if="json.slides[1]" :src="json.slides[1].image" alt="" />
                                             <img class="slideimage" :src="json.slides[0].image" alt="" />
                                        </div>

                                        <div class="slidetitle">
                                             Deck Score
                                        </div>

                                        <div class="scores columns is-mobile">
                                             <div class="column" style="text-align:left">
                                                  <span class="name "> Font </span>
                                                  <span class="value"> {{ deckscores.font }}/10 </span>
                                             </div>
                                             <div class="column">
                                                  <span class="name "> Color </span>
                                                  <span class="value"> {{ deckscores.color }}/10 </span>
                                             </div>
                                             <div class="column" style="text-align:right">
                                                  <span class="name "> Contrast </span>
                                                  <span class="value"> {{ deckscores.contrast }}/10 </span>
                                             </div>
                                        </div>

                                        <div class="suggestions">
                                             <div class="suggestion" v-if="deckscores.fontType < 5 || deckscores.fontSize < 5">
                                                  <span v-if="deckscores.fontType < 6 && deckscores.fontSize < 6">
                                                       Fonts are your digital handwriting. Consider using fewer types and consistent sizes to keep it
                                                       comfortable for the eyes.
                                                  </span>
                                                  <span v-else-if="deckscores.fontColor < 6">
                                                       Too many font types can lead to distraction. Try using a consistent font type through the deck,
                                                       changing only for emphasis.
                                                  </span>
                                                  <span v-else>
                                                       This slide may contain too many different font sizes. Font sizes denote hierarchy. Consider
                                                       reducing it and making it consistent across the slide deck.
                                                  </span>
                                             </div>
                                             <div class="suggestion" v-if="deckscores.fontColor < 6">
                                                  The slide may contain too many colors. Fewer and deliberate use of colors allows for focus.
                                             </div>
                                             <div class="suggestion" v-if="deckscores.contrast < 6">
                                                  Higher contrast reads to better readability & accessibility. Consider increasing contrast of
                                                  foreground text with background colors.
                                             </div>
                                        </div>
                                   </div>
                              </slide>

                              <slide class="slide " v-for="(item, index) in json.slides" :key="index">
                                   <div class="slidecontainer  ">
                                        <img class="slideimage" :src="item.image" alt="" />

                                        <h2 class="slidetitle">
                                             Slide Score
                                        </h2>

                                        <div class="scores columns is-mobile">
                                             <div class="column" style="text-align:left">
                                                  <span class="name "> Font</span>
                                                  <span class="value"> {{ item.slideScore.fontScore }}/10</span>
                                             </div>
                                             <div class="column">
                                                  <span class="name "> Color </span>
                                                  <span class="value"> {{ item.slideScore.fontColorScore }}/10 </span>
                                             </div>
                                             <div class="column" style="text-align:right">
                                                  <span class="name "> Contrast </span>
                                                  <span class="value"> {{ item.slideScore.fontContrastScore }}/10 </span>
                                             </div>
                                        </div>

                                        <div class="suggestions">
                                             <div class="suggestion" v-if="item.slideScore.fontTypeScore == 0 || item.slideScore.fontSizeScore == 0">
                                                  <span v-if="item.slideScore.fontTypeScore == 0 && item.slideScore.fontSizeScore == 0">
                                                       Fonts are your digital handwriting. Consider using fewer types and consistent sizes to keep it
                                                       comfortable for the eyes.
                                                  </span>
                                                  <span v-else-if="item.slideScore.fontColorScore === 0">
                                                       Too many font types can lead to distraction. Try using a consistent font type through the deck,
                                                       changing only for emphasis.
                                                  </span>
                                                  <span v-else>
                                                       This slide may contain too many different font sizes. Font sizes denote hierarchy. Consider
                                                       reducing it and making it consistent across the slide deck.
                                                  </span>
                                             </div>
                                             <div class="suggestion" v-if="item.slideScore.fontColorScore == 0">
                                                  The slide may contain too many colors. Fewer and deliberate use of colors allows for focus.
                                             </div>
                                             <div class="suggestion" v-if="item.slideScore.fontContrastScore < 5">
                                                  Higher contrast reads to better readability & accessibility. Consider increasing contrast of
                                                  foreground text with background colors.
                                             </div>
                                        </div>
                                   </div>
                              </slide>
                         </hooper>
                    </div>
               </div>
          </div>

          <div v-else-if="!errorState" style="text-align: center;margin-top: 100px">
               <spinner :color="'#f2836b'" :size="'20px'"></spinner>
          </div>

          <!-- <b-button @click="clickMe" class="coral"> Click Me</b-button>
          <b-button type="is-coral" class="worksans">Primary</b-button> -->
     </div>
</template>
<script>
     import { Hooper, Slide, Navigation as HooperNavigation, Pagination as HooperPagination } from "hooper";
     import spinner from "vue-spinner/src/ScaleLoader.vue";

     import "hooper/dist/hooper.css";
     import axios from "axios";
     import { calculateScores } from "../actions/calculateScores";

     export default {
          data() {
               return {
                    dataReady: false,
                    errorState: false,

                    jsonURL: !this.$route.query.encoded ? this.$route.query.jsonURL : decodeURIComponent(this.$route.query.jsonURL),
                    pptURL: !this.$route.query.encoded ? this.$route.query.pptURL : decodeURIComponent(this.$route.query.pptURL),

                    json: undefined,

                    hooperSettingsVertical: {
                         itemsToShow: 6,
                         shortDrag: false,
                         vertical: true,
                    },
                    hooperSettingsHorizontal: {
                         itemsToShow: 3,
                         shortDrag: false,
                         vertical: false,
                    },
                    deckscores: {
                         fontSize: 0,
                         fontType: 0,
                         fontColor: 0,
                         contrast: 0,
                         font: 0,
                         color: 0,
                    },
               };
          },

          components: {
               Hooper,
               Slide,
               HooperNavigation,
               HooperPagination,
               spinner,
          },

          methods: {
               changeSlide(num) {
                    this.$refs.carouselMain.slideTo(num);
               },

               async getAndProcessJSON() {
                    if (this.$store.state.dataJSON !== undefined) {
                         try {
                              if (typeof this.$store.state.dataJSON == "string") {
                                   this.json = JSON.parse(this.$store.state.dataJSON);
                              } else {
                                   this.json = this.$store.state.dataJSON;
                              }
                         } catch (e) {
                              this.processError("Could not process results");
                         }
                    } else {
                         try {
                              const response = await axios.get(this.jsonURL);

                              if (response && response.status === 200) {
                                   try {
                                        if (typeof response.data == "string") {
                                             this.json = JSON.parse(response.data);
                                        } else {
                                             this.json = response.data;
                                        }
                                   } catch (e) {
                                        this.processError("Could not process results");
                                   }
                              } else {
                                   this.processError("This file has expired");
                              }
                         } catch (e) {
                              this.errorState = true;

                              this.processError("This file has expired");
                         }
                    }

                    if (!this.errorState && this.json.slides && this.json.slides.length !== undefined && this.json.slides.length > 0) {
                         try {
                              const dataAfterScoresCalculated = await calculateScores(this.json);
                              this.json = dataAfterScoresCalculated;
                              this.calculateDeckScores(this.json);
                              this.dataReady = true;
                         } catch (e) {
                              this.processError("Could not calculate scores");
                              this.dataReady = false;
                         }
                    } else {
                         this.processError("Could not process results");
                    }
               },

               processError(errorMessage) {
                    this.$buefy.toast.open({
                         message: errorMessage,
                         position: "is-bottom",
                         type: "is-danger",
                         duration: "4000",
                    });
                    this.reset();
               },

               calculateDeckScores(data) {
                    const numberOfSlides = data.slides.length;
                    let fontTypes = 0;
                    let fontColors = 0;
                    let fontContrasts = 0;
                    let fontSizes = 0;

                    data.slides.forEach((slide) => {
                         const slideScore = slide.slideScore;

                         if (slideScore.fontColorScore == 10) {
                              fontColors++;
                         }
                         if (slideScore.fontSizeScore == 10) {
                              fontSizes++;
                         }
                         if (slideScore.fontTypeScore == 10) {
                              fontTypes++;
                         }

                         if (typeof slideScore.fontContrastScore == "number" && !isNaN(slideScore.fontContrastScore)) {
                              fontContrasts += slideScore.fontContrastScore;
                         } else {
                              fontContrasts += 10;
                         }
                    });

                    this.deckscores.fontType = Math.round((fontTypes / numberOfSlides) * 10);
                    this.deckscores.fontColor = Math.round((fontColors / numberOfSlides) * 10);
                    this.deckscores.fontSize = Math.round((fontSizes / numberOfSlides) * 10);

                    this.deckscores.contrast = Math.round(fontContrasts / numberOfSlides);
                    this.deckscores.font = Math.round((this.deckscores.fontType + this.deckscores.fontSize) / 2);
                    this.deckscores.color = this.deckscores.fontColor;
               },
          },

          async mounted() {
               await this.getAndProcessJSON();
          },
     };
</script>

<style lang="scss" scoped>
     @import "@/assets/styles/variables.scss";

     .darkmode {
          .scores {
               .name {
                    color: $tertiary_dark;
               }
               .value {
                    background: $primary;
               }
          }
          .suggestions {
               .suggestion {
                    background: #333b56;
                    color: $secondary_dark_2;
                    box-shadow: 0px 6.26856px 25.0742px $shadow_dark !important;
               }
          }
          .slidetitle {
               color: $secondary_dark_2;
          }
     }

     .slidetitle {
          font-weight: 600;
          font-size: 19px;
          text-align: center;
          margin-top: 30px;
          margin-bottom: 30px;
          color: $secondary_light_2;
     }
     .resultcontainer {
          padding: 110px 0px;
     }

     .downloadbtn {
          margin-top: 60px;
          font-weight: 500;
     }

  
    
     .scores {
          margin: 40px auto;

          .name:nth-of-type(1) {
               text-align: left;
          }
          .name:nth-of-type(2) {
               text-align: center;
          }
          .name:nth-of-type(3) {
               text-align: right;
          }

          @media only screen and (max-width: 1023px) {
               .name:nth-of-type(1) {
                    text-align: center;
               }
               .name:nth-of-type(2) {
                    text-align: center;
               }
               .name:nth-of-type(3) {
                    text-align: center;
               }
          }
          .name {
               display: inline-block;

               font-weight: 500;
               margin-right: 13px;
               font-size: 16px;
               color: $tertiary_light;

               @media only screen and (max-width: 1023px) {
                    margin-right: 5px;
                    font-size: 13px;
                    display: block;
               }
          }
          .value {
               @media only screen and (max-width: 1023px) {
                    display: block;
                    padding: 4px 6px;
                    font-size: 13px;
                    margin: 10px auto;
                    width: 60px;
               }
               font-style: normal;
               font-weight: 600;
               font-size: 16px;
               display: inline-block;

               padding: 6px 8px;
               border-radius: 8px;
               align-items: center;
               text-align: center;
               color: #ffffff;
               background: rgba(242, 131, 107, 0.76);
          }
     }
     .suggestions {
          margin: 40px auto;

          .suggestion {
               transition: color 0.8s;
               transition: background-color 0.8s;
               color: $secondary_light_2;
               background: $bg_secondary_light;
               text-align: justify;
               box-shadow: 0px 16.0119px 64.0477px $shadow_light;
               border-radius: 19.2143px;
               padding: 30px;
               margin-bottom: 30px;
          }
     }

     .hidden-mobile {
          display: initial !important;
     }
     .hidden-desktop {
          display: none !important;
     }

     @media only screen and (max-width: 1023px) {
          .resultcontainer {
               padding: 50px 0px;
          }
          h2 {
               font-size: 30px;
          }
          .hidden-mobile {
               display: none !important;
          }
          .hidden-desktop {
               display: initial !important;
          }

          .is-9-desktop {
               padding: 0;
          }
     }
</style>

<style lang="scss">
     .hooper {
          outline: none;
          * {
               outline: none;
          }
     }

     .horizontalCarouselContainer {
          display: block;
          width: 100%;
          height: 250px;
          padding: 0 20px;

          #carouselControlHorizontal {
               margin-top: 70px;
               margin-bottom: 50px;

               width: 100%;
               .hooper-pagination {
                    top: 113px;
               }
               .hooper-navigation {
                    .hooper-prev,
                    .hooper-next {
                         top: 130px !important;
                    }
               }
               .slide {
                    .slidecontainer {
                         width: 100%;
                         height: 100%;
                         text-align: center;
                         padding: 0 5px;

                         img {
                              display: block;
                              width: 100%;
                              height: auto;
                         }

                         .imagestack {
                              position: relative;

                              width: 100%;
                              height: auto !important;
                              text-align: left;
                              padding-bottom: 13px;

                              .slideimage:nth-of-type(1) {
                                   z-index: 0;
                                   width: calc(100% - 13px);
                                   height: auto;
                              }
                              .slideimage:nth-of-type(2) {
                                   position: absolute;
                                   top: 7px;
                                   left: 7px;
                                   z-index: 1;
                                   width: calc(100% - 13px);
                                   height: auto;
                              }
                              .slideimage:nth-of-type(3) {
                                   position: absolute;
                                   top: 13px;
                                   left: 13px;
                                   z-index: 2;
                                   width: calc(100% - 13px);
                                   height: auto;
                              }
                         }
                    }
               }
          }
     }

     .carouselContainer {
          margin: auto;
          margin-top: 120px;
          max-width: 1000px;
          max-width: 100%;

          #carouselControlVertical {
               height: 730px !important;

               .hooper-pagination.is-vertical {
                    right: -10px;
               }
               .hooper-navigation.is-vertical {
                    .hooper-prev,
                    .hooper-next {
                         right: -10px !important;
                    }
               }
               .slide {
                    .slidecontainer {
                         padding-bottom: 10px;
                         width: 100%;
                         height: 100%;
                         text-align: left;
                         padding-right: 40px;

                         img {
                              display: block;
                              height: 100%;
                              width: auto;
                              padding: 5px;
                         }

                         .imagestack {
                              position: relative;

                              width: 100%;
                              height: 100%;

                              text-align: left;

                              .slideimage {
                                   padding: 0 !important;
                                   height: 100%;
                                   width: auto !important;
                              }

                              .slideimage:nth-of-type(1) {
                                   z-index: 0;
                                   position: relative;
                                   top: 0px;
                                   left: 0px;
                                   height: 100%;
                                   height: calc(100% - 16px);
                                   width: auto;
                              }
                              .slideimage:nth-of-type(2) {
                                   position: absolute;
                                   top: 7px;
                                   left: 7px;
                                   z-index: 1;
                                   height: 100%;
                                   height: calc(100% - 16px);
                                   width: auto;
                              }
                              .slideimage:nth-of-type(3) {
                                   position: absolute;
                                   top: 14px;
                                   left: 14px;
                                   z-index: 2;
                                   height: 100%;
                                   height: calc(100% - 16px);
                                   width: auto;
                              }
                         }
                    }
               }
          }

          #carouselMain {
               width: 100%;

               .slide {
                    width: 100% !important;
                    text-align: center;

                    .slidecontainer {
                         padding: 0 30px 0 50px;

                         @media only screen and (max-width: 1023px) {
                              padding: 0 20px;
                         }

                         .slideimage {
                              width: 100%;
                              height: auto;
                         }

                         .imagestack {
                              position: relative;

                              width: 100%;
                              height: auto !important;
                              text-align: left;
                              padding-bottom: 26px;

                              .slideimage {
                                   width: 100%;
                                   height: auto !important;
                              }

                              .slideimage:nth-of-type(1) {
                                   z-index: 0;
                                   width: calc(100% - 26px);
                                   height: auto;
                              }
                              .slideimage:nth-of-type(2) {
                                   position: absolute;
                                   top: 13px;
                                   left: 13px;
                                   z-index: 1;
                                   width: calc(100% - 26px);
                                   height: auto;
                              }
                              .slideimage:nth-of-type(3) {
                                   position: absolute;
                                   top: 26px;
                                   left: 26px;
                                   z-index: 2;
                                   width: calc(100% - 26px);
                                   height: auto;
                              }
                         }
                    }
               }
          }
     }

     @media only screen and (max-width: 1023px) {
          .carouselContainer {
               margin-top: 30px;
          }
     }
</style>
