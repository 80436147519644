import { base64toRGB, getSectionFromThumbnail, findContrast, asyncImageLoader } from './helpers';

function rgbToArray(rgb) {
     const splitted = rgb
          .split('(')[1]
          .split(')')[0]
          .split(',');
     return [parseInt(splitted[0]), parseInt(splitted[1]), parseInt(splitted[2])];
}

function RGBtoString(arr) {
     return `rgb(${arr[0]},${arr[1]},${arr[2]})`;
}

async function fillToRGB(fill) {
     if (fill) {
          if (fill.fillType && fill.fillType == 'solid') {
               return rgbToArray(fill.solidColor);
          } else if (fill.fillType && fill.fillType == 'picture') {
               const averagergb = await base64toRGB(fill.pictureFormat.image);
               return averagergb;
          } else return false;
     }
}

class ArrayItem {
     constructor() {
          this.values = [];
     }
     add(value) {
          if (this.values.indexOf(value) === -1) {
               this.values.push(value);
          }
     }
     push(value) {
          this.values.push(value);
     }
}
class Scores {
     constructor() {
          this.fontTypes = new ArrayItem();
          this.fontColors = new ArrayItem();
          this.fontSizes = new ArrayItem();
          this.contrastScores = new ArrayItem();

          this.fontContrastScore = 10;
          this.fontTypeScore = 10;
          this.fontColorScore = 10;
          this.fontSizeScore = 10;

          this.totalScore = 10;
     }
     printValues() {
          // console.log('fontTypes', this.fontTypes.values);
          // console.log('fontColors', this.fontColors.values);
          // console.log('fontSizes', this.fontSizes.values);
          // console.log('contrastScores', this.contrastScores.values);

          // console.log('');
          console.log('fontContrastScore', this.fontContrastScore);
          console.log('fontTypeScore', this.fontTypeScore);
          console.log('fontColorScore', this.fontColorScore);
          console.log('fontSizeScore', this.fontSizeScore);
          console.log('------------------------');
     }

     getOutput() {
          return {
               fontTypes: this.fontTypes.values,
               fontColors: this.fontColors.values,
               fontSizes: this.fontSizes.values,
               contrastScores: this.contrastScores.values,

               fontContrastScore: this.fontContrastScore,
               fontTypeScore: this.fontTypeScore,
               fontColorScore: this.fontColorScore,
               fontSizeScore: this.fontSizeScore,
               fontScore: Math.round(this.fontTypeScore + this.fontSizeScore) / 2,

               totalScore: this.totalScore,
          };
     }

     calculate() {
          //font type
          if (this.fontTypes.values.length < 3) {
               this.fontTypeScore = 10;
          } else {
               this.fontTypeScore = 0;
          }

          //font color
          if (this.fontColors.values.length < 5) {
               this.fontColorScore = 10;
          } else {
               this.fontColorScore = 0;
          }

          //font size
          if (this.fontSizes.values.length < 6) {
               this.fontSizeScore = 10;
          } else {
               this.fontSizeScore = 0;
          }

          //contrast
          //get average of all contrast scores
          let total = 0;

          for (let i = 0; i < this.contrastScores.values.length; i++) {
               total += this.contrastScores.values[i];
          }
          let avgScore;
          if (total > 0) {
               avgScore = Math.round(total / this.contrastScores.values.length);
          } else {
               avgScore = 0;
          }
          this.fontContrastScore = avgScore;

          this.fontScore = Math.round((this.fontSizeScore + this.fontTypeScore) / 2);
          this.color;

          this.totalScore = Math.round(((this.fontContrastScore + this.fontTypeScore + this.fontColorScore + this.fontSizeScore) / 40) * 10);
     }
}

async function slideThumbnailObject(img) {
     const imageObject = {};
     imageObject.image = await asyncImageLoader(img);
     imageObject.canvas = document.createElement('canvas');
     imageObject.context = imageObject.canvas.getContext && imageObject.canvas.getContext('2d');
     imageObject.height = imageObject.canvas.height = imageObject.image.height;
     imageObject.width = imageObject.canvas.width = imageObject.image.width;
     //  console.log("slide dim",imageObject.width,imageObject.height)

     imageObject.context.drawImage(imageObject.image, 0, 0);

     return imageObject;
}

async function calculateScores(data) {
     const deckScores = new Scores();

     const slides = data.slides;
     //  slides.forEach(async slide => {

     for (let i = 0; i < slides.length; i++) {
          const slide = slides[i];

          const slideThumbnail = await slideThumbnailObject(slide.image);

          const slideScores = new Scores();

          const textItems = slide.textItems;

          const slideBackgroundColor = await fillToRGB(slide.backgroundFill);

          // console.log("slide background", slideBackgroundColor)

          //find slide background color

          for (let j = 0; j < textItems.length; j++) {
               const textFrame = textItems[j];

               if (!textFrame.paragraphs || textFrame.empty == 'true') {
                    continue;
               }
               const textFrameBackgroundColor = await fillToRGB(textFrame.backgroundFill);

               for (let k = 0; k < textFrame.paragraphs.length; k++) {
                    const paragraph = textFrame.paragraphs[k];

                    for (let l = 0; l < paragraph.sections.length; l++) {
                         const section = paragraph.sections[l];

                         // console.log(section.text,section.textFill);

                         const fontColor = await fillToRGB(section.textFill);

                         //font type
                         slideScores.fontTypes.add(section.latinFont);
                         deckScores.fontTypes.add(section.latinFont);

                         //color
                         if (fontColor) {
                              slideScores.fontColors.add(RGBtoString(fontColor));
                              deckScores.fontColors.add(RGBtoString(fontColor));
                         }

                         //size
                         slideScores.fontSizes.add(section.fontSize);
                         deckScores.fontSizes.add(section.fontSize);

                         //contrast;

                         // METHOD1--using background fill

                         let backgroundColor = textFrameBackgroundColor || slideBackgroundColor;

                         //check if highlighted

                         if (section.isHighlighted == 'true') {
                              backgroundColor = rgbToArray(section.highlightColor);
                         }

                         const contrastScore = findContrast(backgroundColor, fontColor);
 
                         section.contrast = {
                              score: contrastScore,
                              backgroundColor: RGBtoString(backgroundColor),
                              fontColor: RGBtoString(fontColor),
                         };

                         //METHOD2-- using slide thumbnail;

                         const sectionImage = await getSectionFromThumbnail(
                              slideThumbnail,
                              parseInt(section.positionX) + parseInt(textFrame.positionX),
                              parseInt(section.positionY) + parseInt(textFrame.positionY),
                              parseInt(section.width) + 5,
                              parseInt(section.height) + 5
                         );
                         section.image = sectionImage.image;
                         const contrastScore2 = findContrast(sectionImage.backgroundColor, fontColor) ;
                        
                         section.contrast2 = {
                              score: contrastScore2,
                              backgroundColor: RGBtoString(sectionImage.backgroundColor),
                              fontColor: RGBtoString(fontColor),
                         };

                         //use contrast score2
                         slideScores.contrastScores.push(contrastScore2);
                         deckScores.contrastScores.push(contrastScore2);
                    }
               }
          }

          slideScores.calculate();

          slide.slideScore = slideScores.getOutput();
     }

     deckScores.calculate();

     data.deckScore = deckScores.getOutput();
     return data;
}

// ------------------------------------------------------

export { calculateScores };
