function luminance(r, g, b) {
     const a = [r, g, b].map(function(v) {
          v /= 255;
          return v <= 0.03928 ? v / 12.92 : Math.pow((v + 0.055) / 1.055, 2.4);
     });
     return a[0] * 0.2126 + a[1] * 0.7152 + a[2] * 0.0722;
}
function findContrast(rgb1, rgb2) {
     const lum1 = luminance(rgb1[0], rgb1[1], rgb1[2]);
     const lum2 = luminance(rgb2[0], rgb2[1], rgb2[2]);
     const brightest = Math.max(lum1, lum2);
     const darkest = Math.min(lum1, lum2);
     let contrast = (brightest + 0.05) / (darkest + 0.05);

  
     contrast *= 6;


     let contrastPercent = Math.round((contrast / 20) * 10);

     if (contrastPercent > 10) {
          contrastPercent = 10;
     }
     return contrastPercent;
}

// ------------------------------------------------------------------------------------
function asyncImageLoader(img) {
     return new Promise((resolve, reject) => {
          const image = new Image();
          image.onload = () => resolve(image);
          image.onerror = () => reject(new Error('could not load image'));
          image.src = img;
     });
}

function imageDataToPixelAvg(imageData) {
     const blockSize = 5;
     const rgb = { r: 0, g: 0, b: 0 };
     const length = imageData.data.length;
     let i = -4;
     let count = 0;
     while ((i += blockSize * 4) < length) {
          ++count;
          rgb.r += imageData.data[i];
          rgb.g += imageData.data[i + 1];
          rgb.b += imageData.data[i + 2];
     }

     // ~~ used to floor values

     rgb.r = ~~(rgb.r / count);
     rgb.g = ~~(rgb.g / count);
     rgb.b = ~~(rgb.b / count);

     return [rgb.r, rgb.g, rgb.b];
}

async function base64toRGB(img) {
     // only visit every 5 pixels
     const canvas = document.createElement('canvas');
     const context = canvas.getContext && canvas.getContext('2d');

     if (!context) {
          return false;
     }

     const bgImage = await asyncImageLoader(img);

     const height = (canvas.height = bgImage.height);
     const width = (canvas.width = bgImage.width);
     context.drawImage(bgImage, 0, 0);

     try {
          const imageData = context.getImageData(0, 0, width, height);
          return imageDataToPixelAvg(imageData);
     } catch (e) {
          console.log('Error', e);
          return false;
     }
}

// ------------------------------------------------------------------------------------

async function getSectionFromThumbnail(slideThumbnail, sectionPositionX, sectionPositionY, sectionWidth, sectionHeight) {
     const subImage = slideThumbnail.context.getImageData(sectionPositionX, sectionPositionY, sectionWidth, sectionHeight);
     // let subImage=slideThumbnail.image;

     const canvas = document.createElement('canvas');
     const context = canvas.getContext('2d');
     canvas.height = subImage.height;
     canvas.width = subImage.width;

     context.putImageData(subImage, 0, 0);

     const dataURL = canvas.toDataURL('image/png');
     return { image: dataURL, backgroundColor: imageDataToPixelAvg(subImage) };
}

// ------------------------------------------------------------------------------------

export { base64toRGB, findContrast, getSectionFromThumbnail, asyncImageLoader, imageDataToPixelAvg };
